import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Box)({
  backgroundColor: '#1c1c1c', // Dark grey background
  color: '#fff',
  padding: '2rem',
  borderRadius: '10px',
  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
});

const TermsOfService = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <StyledContainer maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Pocket Quant Website, Mobile App, and Newsletter Terms of Use
        </Typography>
        <Typography variant="body1" paragraph>
          Last Modified: July 20, 2024
        </Typography>
        <Typography variant="body1" paragraph>
          1. Acceptance of the Terms of Use
          These terms of use are entered into by and between you and Bottle Blue LLC (“Bottle Blue”, “we” or “us”). The following terms and conditions (these “Terms of Use”) govern your access to and use of https://www.pocketquant.io, including any content, functionality and services offered on or through this website (the “Website”), our newsletter, and our mobile application (“Mobile App”), whether as a guest or a registered user.
        </Typography>
        <Typography variant="body1" paragraph>
          Please read the Terms of Use carefully before you start to use the Website, Mobile App, or newsletter. By using the Website, Mobile App, or subscribing to our newsletter, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website, Mobile App, or subscribe to our newsletter.
        </Typography>
        <Typography variant="body1" paragraph>
          Our Website, Mobile App, and newsletter are offered and available to users who are 18 years of age or older. By using our Website, Mobile App, or subscribing to our newsletter, you represent and warrant that you are of legal age to form a binding contract with Bottle Blue LLC and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website, Mobile App, or subscribe to our newsletter.
        </Typography>
        <Typography variant="body1" paragraph>
          THESE TERMS REQUIRE DISPUTE RESOLUTION ON AN INDIVIDUAL BASIS, RATHER THAN A CLASS ACTION.
        </Typography>
        <Typography variant="body1" paragraph>
          2. No Investment Advice; Investment Risks
          BY USING THE WEBSITE OR MOBILE APP OR SUBSCRIBING TO OUR NEWSLETTER, YOU ACKNOWLEDGE AND AGREE THAT THE CONTENT PUBLISHED ON OUR WEBSITE, MOBILE APP, AND NEWSLETTER IS FOR INFORMATIONAL PURPOSES OF A GENERAL NATURE ONLY, AND DOES NOT CONSTITUTE A RECOMMENDATION THAT ANY PARTICULAR INVESTMENT, SECURITY, PORTFOLIO OF SECURITIES, TRANSACTION, OR INVESTMENT STRATEGY IS SUITABLE FOR YOU OR ANY OTHER SPECIFIC PERSON. NOTHING CONTAINED ON OUR WEBSITE, MOBILE APP, OR NEWSLETTER CONSTITUTES A SOLICITATION, RECOMMENDATION, ENDORSEMENT, OR OFFER BY BOTTLE BLUE LLC OR ANY THIRD PARTY PROVIDER TO BUY OR SELL ANY SECURITIES OR OTHER FINANCIAL INSTRUMENTS IN ANY JURISDICTION, INCLUDING ANY JURISDICTION IN WHICH SUCH SOLICITATION OR OFFER WOULD BE UNLAWFUL UNDER THE SECURITIES LAWS OF SUCH JURISDICTION. BOTTLE BLUE LLC IS NOT A FIDUCIARY AND WILL NOT HAVE ANY FIDUCIARY DUTIES TO YOU OR ANY THIRD PARTY BY VIRTUE OF ANY USE OF THE WEBSITE, MOBILE APP, OR NEWSLETTER. YOU SHOULD SEEK PROFESSIONAL INVESTMENT ADVICE PRIOR TO ENGAGING IN ANY INVESTMENT TRANSACTION.
        </Typography>
        <Typography variant="body1" paragraph>
          YOU FURTHER UNDERSTAND THAT THE VIEWS EXPRESSED ON OR THROUGH THE WEBSITE, MOBILE APP, OR NEWSLETTER ARE THE AUTHORS’ OWN OPINIONS, AND THAT THE WEBSITE, MOBILE APP, OR NEWSLETTER MAY CONTAIN OPINIONS FROM TIME TO TIME WITH REGARD TO SECURITIES OR OTHER TYPES OF INVESTMENTS MENTIONED IN OTHER AREAS AVAILABLE ON OR THROUGH THE WEBSITE, MOBILE APP, OR NEWSLETTER, AND MAY CONFLICT WITH THE OPINIONS EXPRESSED IN OTHER AREAS AVAILABLE ON OR THROUGH THE WEBSITE, MOBILE APP, OR NEWSLETTER.
        </Typography>
        <Typography variant="body1" paragraph>
          YOU FURTHER UNDERSTAND THAT TRADING IN SECURITIES (INCLUDING STOCKS, OPTIONS, ETFS, BONDS, AND OTHER INVESTMENT VEHICLES) INVOLVES RISK AND VOLATILITY. LOSS OF PRINCIPAL IS POSSIBLE. PAST RESULTS ARE NOT NECESSARILY INDICATIVE OF FUTURE PERFORMANCE. OUR PAST BACKTESTS, ANALYSIS, AND STRATEGY RESULTS ARE NOT A GUARANTEE OF FUTURE RESULTS. USING ANY GRAPH, CHART, FORMULA, OR OTHER DEVICE TO ASSIST IN DECIDING WHICH SECURITIES TO TRADE OR WHEN TO TRADE THEM PRESENTS MANY DIFFICULTIES AND THEIR EFFECTIVENESS HAS SIGNIFICANT LIMITATIONS, INCLUDING THAT PRIOR PATTERNS MAY NOT REPEAT THEMSELVES CONTINUOUSLY OR ON ANY PARTICULAR OCCASION. IN ADDITION, MARKET PARTICIPANTS USING SUCH DEVICES CAN IMPACT THE MARKET IN A WAY THAT CHANGES THE EFFECTIVENESS OF SUCH DEVICE.
        </Typography>
        <Typography variant="body1" paragraph>
          BY ACCESSING AND USING OUR WEBSITE, MOBILE APP, OR NEWSLETTER, YOU AGREE NOT TO HOLD BOTTLE BLUE LLC, ITS AFFILIATES, OR ANY OTHER THIRD PARTY SERVICE PROVIDER TO BOTTLE BLUE LLC LIABLE FOR ANY POSSIBLE CLAIM FOR DAMAGES ARISING FROM ANY DECISION OR ACTION THAT YOU MAKE OR NOT MAKE BASED ON YOUR USE OF THE WEBSITE, MOBILE APP, OR NEWSLETTER, INCLUDING THE INFORMATION OR OTHER CONTENT MADE AVAILABLE TO YOU ON OR THROUGH THE WEBSITE, MOBILE APP, OR NEWSLETTER.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Changes to the Terms of Use
          We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Website, Mobile App, and newsletter thereafter.
        </Typography>
        <Typography variant="body1" paragraph>
          Your continued use of the Website, Mobile App, or newsletter following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.
        </Typography>
        <Typography variant="body1" paragraph>
          4. Accessing the Website or Mobile App and Account Security
          We reserve the right to withdraw or amend the Website, Mobile App, and newsletter, and any service or material we provide on the Website, Mobile App, and newsletter, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website, Mobile App, or newsletter is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, Mobile App, or newsletter, or the entire Website, Mobile App, or newsletter, to users, including registered users.
        </Typography>
        <Typography variant="body1" paragraph>
          You are responsible for:
        </Typography>
        <Typography variant="body1" paragraph>
          · Making all arrangements necessary for you to have access to the Website, Mobile App, and newsletter.
        </Typography>
        <Typography variant="body1" paragraph>
          · Ensuring that all persons who access the Website, Mobile App, or newsletter through your internet connection are aware of these Terms of Use and comply with them.
        </Typography>
        <Typography variant="body1" paragraph>
          To access the Website, Mobile App, or newsletter or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website, Mobile App, or newsletter that all the information you provide on the Website, Mobile App, or newsletter is correct, current, and complete. You agree that all information you provide to register with this Website, Mobile App, or newsletter or otherwise, including but not limited to through the use of any interactive features on the Website, Mobile App, or newsletter, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.
        </Typography>
        <Typography variant="body1" paragraph>
          If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Website, Mobile App, or newsletter or portions of it using your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you sign out of your account at the end of each session when using a shared computer or device. You should use particular caution when accessing your account from a public or shared computer or device so that others are not able to access your account, view or record your password, or obtain other personal information.
        </Typography>
        <Typography variant="body1" paragraph>
          We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, and to terminate any subscription, at any time, in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use. We may also disable your access or terminate your subscription, if we receive a judicial or other governmental demand or order, subpoena, or law enforcement request that expressly or by reasonable implication requires us to do so.
        </Typography>
        <Typography variant="body1" paragraph>
          5. Intellectual Property Rights
          The Website, Mobile App, and newsletter and their entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by Bottle Blue LLC, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms of Use permit you to use the Website, Mobile App, and newsletter for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, Mobile App, or newsletter, except as necessary to properly display the contents of the Website, Mobile App, or newsletter on your device when you access the Website, Mobile App, or newsletter. In addition, you may print or download one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication, or distribution.
        </Typography>
        <Typography variant="body1" paragraph>
          You must not:
        </Typography>
        <Typography variant="body1" paragraph>
          · Modify copies of any materials from this site.
        </Typography>
        <Typography variant="body1" paragraph>
          · Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.
        </Typography>
        <Typography variant="body1" paragraph>
          You must not access or use for any commercial purposes any part of the Website, Mobile App, or newsletter or any services or materials available through the Website, Mobile App, or newsletter. Commercial purposes do not include personal securities trading activities.
        </Typography>
        <Typography variant="body1" paragraph>
          If you wish to make any use of material on the Website, Mobile App, or newsletter other than that set out in this section, please address your request to: business@bottleblue.biz.
        </Typography>
        <Typography variant="body1" paragraph>
          If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website, Mobile App, or newsletter in breach of the Terms of Use, your right to use the Website, Mobile App, or newsletter will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Website, Mobile App, or newsletter or any content on the Website, Mobile App, or newsletter is transferred to you, and all rights not expressly granted are reserved by Bottle Blue LLC. Any use of the Website, Mobile App, or newsletter not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.
        </Typography>
        <Typography variant="body1" paragraph>
          6. Trademarks
          The Bottle Blue LLC name, the term Pocket Quant, the Pocket Quant logo, and all related names, logos, product and service names, designs, and slogans are trademarks of Bottle Blue LLC or its affiliates or licensors. You must not use such marks without the prior written permission of Bottle Blue LLC. All other names, logos, product, and service names, designs, and slogans on the Website, Mobile App, or newsletter are the trademarks of their respective owners.
        </Typography>
        <Typography variant="body1" paragraph>
          7. Prohibited Uses
          You may use the Website, Mobile App, and newsletter only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website, Mobile App, or newsletter:
        </Typography>
        <Typography variant="body1" paragraph>
          · In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).
        </Typography>
        <Typography variant="body1" paragraph>
          · To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Website, Mobile App, or newsletter, or which, as determined by us, may harm Bottle Blue LLC or users of the Website, Mobile App, or newsletter or expose them to liability.
        </Typography>
        <Typography variant="body1" paragraph>
          Additionally, you agree not to:
        </Typography>
        <Typography variant="body1" paragraph>
          · Use the Website, Mobile App, or newsletter in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Website, Mobile App, or newsletter, including their ability to engage in real time activities through the Website, Mobile App, or newsletter.
        </Typography>
        <Typography variant="body1" paragraph>
          · Use any robot, spider, or other automatic device, process, or means to access the Website, Mobile App, or newsletter for any purpose, including monitoring or copying any of the material on the Website, Mobile App, or newsletter.
        </Typography>
        <Typography variant="body1" paragraph>
          · Use any manual process to monitor or copy any of the material on the Website, Mobile App, or newsletter or for any other unauthorized purpose without our prior written consent.
        </Typography>
        <Typography variant="body1" paragraph>
          · Use any device, software, or routine that interferes with the proper working of the Website, Mobile App, or newsletter.
        </Typography>
        <Typography variant="body1" paragraph>
          · Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
        </Typography>
        <Typography variant="body1" paragraph>
          · Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, Mobile App, or newsletter, the servers on which the Website, Mobile App, or newsletter are stored, or any server, computer, or database connected to the Website, Mobile App, or newsletter or data stored therein.
        </Typography>
        <Typography variant="body1" paragraph>
          · Attack the Website, Mobile App, or newsletter via a denial-of-service attack or a distributed denial-of-service attack.
        </Typography>
        <Typography variant="body1" paragraph>
          · Otherwise attempt to interfere with the proper working of the Website, Mobile App, or newsletter.
        </Typography>
        <Typography variant="body1" paragraph>
          8. Reliance on Information Posted
          The information presented on or through the Website, Mobile App, or newsletter is made available solely for general information purposes. Due to various factors, including limitations of the data collection process and our underlying sources, the content on our site may be lagged, outdated, or otherwise inaccurate. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Website, Mobile App, or newsletter, or by anyone who may be informed of any of its contents.
        </Typography>
        <Typography variant="body1" paragraph>
          The Website, Mobile App, and newsletter include content provided by third parties, including materials provided by third-party licensors, syndicators, aggregators, and/or reporting services. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.
        </Typography>
        <Typography variant="body1" paragraph>
          9. No Legal or Accounting Advice
          Bottle Blue LLC is neither a law firm nor a certified public accounting firm and no portion of our Website, Mobile App, newsletter content, or any other correspondence from us should be construed as legal or accounting advice (including any tax advice).
        </Typography>
        <Typography variant="body1" paragraph>
          10. Changes to the Website and Mobile App
          We may update the content on the Website, Mobile App, and newsletter from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Website, Mobile App, or newsletter may be out of date at any given time, and we are under no obligation to update such material.
        </Typography>
        <Typography variant="body1" paragraph>
          11. Information About You and Your Visits to the Website or Mobile App
          All information we collect on our Website, Mobile App, and newsletter is subject to our Privacy Policy. By using the Website, Mobile App, or newsletter, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.
        </Typography>
        <Typography variant="body1" paragraph>
          12. Subscription Terms
          Some areas of our Website, Mobile App, or newsletter may only be accessible if you have purchased a subscription. Subscriptions may be purchased on a month-to-month or yearly basis. You agree that by registering for a subscription, you are offering to buy a subscription for the term you select, and that your subscription is non-cancellable, except as otherwise set forth in these Terms of Use. A subscription is a right and license to access the Website, Mobile App, or newsletter, or a portion thereof, and you do not acquire any ownership interest in the Website, Mobile App, or newsletter or the content provided on the Website, Mobile App, or newsletter other than to use the same in accordance with the limited license granted and subject to all terms, conditions, and restrictions under these Terms of Use. Bottle Blue LLC and its licensors reserve and shall retain their entire right, title, and interest in and to the Website, Mobile App, newsletter and its contents as further described in Section 5 of these Terms of Use.
        </Typography>
        <Typography variant="body1" paragraph>
          You will be billed in advance on a recurring and periodic basis based on the term you select when you register for your subscription (the “Billing Cycle”). At the end of each Billing Cycle, your subscription will automatically renew under the same terms and conditions unless you cancel it or Bottle Blue LLC cancels it. You may cancel your subscription either through your online account management page or by contacting Bottle Blue LLC at the contact information below.
        </Typography>
        <Typography variant="body1" paragraph>
          Prices and terms of payments for subscriptions are subject to change without notice, however, you will only be charged the new price when your current subscription renews. Subscription prices do not include any applicable taxes, if any, which will be added to your total when you purchase the subscription.
        </Typography>
        <Typography variant="body1" paragraph>
          We accept American Express, Visa, Mastercard, and Discover/Diners credit cards for all subscription purchases. You represent and warrant that (i) the credit card information you supply to us is true, correct, and complete, (ii) you are duly authorized to use such credit card for the purchase, (iii) charges incurred by you will be honored by your credit card company, and (iv) you will pay charges incurred by you at the posted prices, including all applicable taxes, if any.
        </Typography>
        <Typography variant="body1" paragraph>
          13. Linking to the Website or Mobile App
          You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express consent. You must not:
        </Typography>
        <Typography variant="body1" paragraph>
          · Establish a link from any website or profile that is not owned by you.
        </Typography>
        <Typography variant="body1" paragraph>
          · Cause the Website, Mobile App, or newsletter or portions of it to be displayed, or appear to be displayed by, for example, framing, deep linking, in-line linking, or posting screenshots on any other site.
        </Typography>
        <Typography variant="body1" paragraph>
          · Otherwise take any action with respect to the materials on the Website, Mobile App, or newsletter that is inconsistent with any other provision of these Terms of Use.
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice.
        </Typography>
        <Typography variant="body1" paragraph>
          14. Links from the Website or Mobile App
          If the Website, Mobile App, or newsletter contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to or on our Website, Mobile App, or newsletter, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.
        </Typography>
        <Typography variant="body1" paragraph>
          15. Geographic Restrictions
          The owner of the Website, Mobile App, and newsletter is based in the state of Minnesota in the United States. We provide the Website, Mobile App, and newsletter for use only by persons located in the United States, Europe, and the United Kingdom. We make no claims that the Website, Mobile App, or newsletter or any of their content is accessible or appropriate outside of the United States, Europe, or the United Kingdom. Access to the Website, Mobile App, or newsletter may not be legal by certain persons or in certain countries. If you access the Website, Mobile App, or newsletter from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.
        </Typography>
        <Typography variant="body1" paragraph>
          16. Disclaimer of Warranties
          You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website, Mobile App, or newsletter will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our Website, Mobile App, or newsletter for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE, MOBILE APP, OR NEWSLETTER OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, MOBILE APP, OR NEWSLETTER OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON THE WEBSITE, MOBILE APP, OR NEWSLETTER, OR ON ANY WEBSITE OR MOBILE APPLICATION LINKED TO THE WEBSITE, MOBILE APP, OR NEWSLETTER.
        </Typography>
        <Typography variant="body1" paragraph>
          YOUR USE OF THE WEBSITE, MOBILE APP, NEWSLETTER, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, MOBILE APP, OR NEWSLETTER IS AT YOUR OWN RISK. THE WEBSITE, MOBILE APP, NEWSLETTER, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, MOBILE APP, OR NEWSLETTER ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER BOTTLE BLUE LLC NOR ANY PERSON ASSOCIATED WITH BOTTLE BLUE LLC MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE, MOBILE APP, OR NEWSLETTER. WITHOUT LIMITING THE FOREGOING, NEITHER BOTTLE BLUE LLC NOR ANYONE ASSOCIATED WITH BOTTLE BLUE LLC REPRESENTS OR WARRANTS THAT THE WEBSITE, MOBILE APP, NEWSLETTER, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, MOBILE APP, OR NEWSLETTER WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE, MOBILE APP, NEWSLETTER, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, MOBILE APP, OR NEWSLETTER WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </Typography>
        <Typography variant="body1" paragraph>
          BOTTLE BLUE LLC HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </Typography>
        <Typography variant="body1" paragraph>
          WE DO NOT GUARANTEE OR WARRANTY THAT ANY DATA SETS, INFORMATION, STRATEGIES, OR OTHER PRODUCTS OR SERVICES WILL PRODUCE A PARTICULAR RESULT OR OUTCOME IF UTILIZED FOR INVESTMENT PURPOSES.
        </Typography>
        <Typography variant="body1" paragraph>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </Typography>
        <Typography variant="body1" paragraph>
          17. Limitation on Liability
          IN NO EVENT WILL BOTTLE BLUE LLC, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, MOBILE APP, OR NEWSLETTER, ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE WEBSITE, MOBILE APP, OR NEWSLETTER, ANY CONTENT ON THE WEBSITE, MOBILE APP, OR NEWSLETTER OR SUCH OTHER WEBSITES OR MOBILE APPLICATIONS, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, MOBILE APP, OR NEWSLETTER OR SUCH OTHER WEBSITES OR MOBILE APPLICATIONS, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. IN THE EVENT YOU HAVE PURCHASED A SUBSCRIPTION, IN NO CIRCUMSTANCES WILL OUR TOTAL LIABILITY EXCEED THE ACTUAL AMOUNT PAID BY YOU FOR YOUR CURRENT TERM OF YOUR SUBSCRIPTION, NOT TO EXCEED TWELVE (12) MONTHS.
        </Typography>
        <Typography variant="body1" paragraph>
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </Typography>
        <Typography variant="body1" paragraph>
          18. Indemnification
          You agree to defend, indemnify, and hold harmless Bottle Blue LLC, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, Mobile App, or newsletter, including, but not limited to, your User Contributions, any use of the Website’s, Mobile App’s, or newsletter’s content, services, and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Website, Mobile App, or newsletter.
        </Typography>
        <Typography variant="body1" paragraph>
          19. Governing Law and Jurisdiction
          All matters relating to the Website, Mobile App, newsletter and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Minnesota without giving effect to any choice or conflict of law provision or rule (whether of the State of Minnesota or any other jurisdiction).
        </Typography>
        <Typography variant="body1" paragraph>
          Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Website, Mobile App, or newsletter shall be instituted exclusively in the federal courts of the United States or the courts of the State of Minnesota. We reserve the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.
        </Typography>
        <Typography variant="body1" paragraph>
          20. Dispute Resolution
          YOU AGREE TO PURSUE ANY CLAIMS YOU MAY HAVE AGAINST BOTTLE BLUE LLC IN THE MONTGOMERY, MN SMALL CLAIMS COURT, IF SUCH COURT HAS JURISDICTION. YOU FURTHER AGREE TO PURSUE ANY OTHER CLAIM YOU MAY HAVE AGAINST BOTTLE BLUE LLC IN STATE OR FEDERAL COURT LOCATED IN MINNESOTA.
        </Typography>
        <Typography variant="body1" paragraph>
          YOU AND BOTTLE BLUE LLC AGREE TO GIVE UP ANY RIGHTS TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM. YOU AGREE TO LITIGATE ON AN INDIVIDUAL BASIS. IN ANY DISPUTE, NEITHER YOU NOR BOTTLE BLUE LLC WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS IN COURT OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY GENERAL CAPACITY.
        </Typography>
        <Typography variant="body1" paragraph>
          This clause shall survive the termination of these Terms of Use. If this specific provision is found to be unenforceable, then (a) the entirety of this provision shall be null and void, but the remaining provisions of these Terms of Use shall remain in full force and effect; and (b) exclusive jurisdiction and venue for any claims will be as set forth above.
        </Typography>
        <Typography variant="body1" paragraph>
          21. Limitation on Time to File Claims
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE, MOBILE APP, OR NEWSLETTER MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
        </Typography>
        <Typography variant="body1" paragraph>
          22. Waiver and Severability
          No waiver by Bottle Blue LLC of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Bottle Blue LLC to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.
        </Typography>
        <Typography variant="body1" paragraph>
          If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.
        </Typography>
        <Typography variant="body1" paragraph>
          23. Entire Agreement
          The Terms of Use, our Privacy Policy, and our API License Agreement if you have purchased access to our API, constitute the sole and entire agreement between you and Bottle Blue LLC with respect to the Website, Mobile App, and newsletter and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to the Website, Mobile App, or newsletter.
        </Typography>
        <Typography variant="body1" paragraph>
          24. Your Comments or Concerns
          This website is operated by Bottle Blue LLC, 415 3rd Street SW, Montgomery, MN 56059, United States.
        </Typography>
        <Typography variant="body1" paragraph>
          All other feedback, comments, requests for technical support and other communications relating to the Website, Mobile App, or newsletter should be directed to: business@bottleblue.biz.
        </Typography>
      </StyledContainer>
    </Box>
  );
};

export default TermsOfService;
