import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Box, Link } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledMessage = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(2),
  color: '#fff',
  padding: theme.spacing(2),
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(1),
  },
}));

const Unsubscribe = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (email) {
      const unsubscribeUser = async () => {
        try {
          await axios.delete('https://8poesonquj.execute-api.us-east-1.amazonaws.com/prod/unsubscribe', { data: { email } });
          setMessage('You have been successfully unsubscribed.');
        } catch (error) {
          setMessage('Something went wrong. Please try again.');
        }
      };
      unsubscribeUser();
    } else {
      setMessage('Invalid or missing email address.');
    }
  }, [email]);

  return (
    <StyledMessage>
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', fontSize: '2.5rem' }}>
        Sorry to see you go!
      </Typography>
      <Typography variant="h6" gutterBottom>
        {message}
      </Typography>
      {message === 'You have been successfully unsubscribed.' && (
        <Typography variant="body2">
          If this was a mistake, you can <Link href="/">subscribe again</Link>.
        </Typography>
      )}
    </StyledMessage>
  );
};

export default Unsubscribe;