import React from 'react';
import { Box, Typography } from '@mui/material';

const PrivacyPolicy = () => (
  <Box p={3} sx={{ color: '#fff', backgroundColor: '#1c1c1c' }}>
    <Typography variant="h4" gutterBottom>Privacy Policy</Typography>
    <Typography variant="body1" paragraph>
      Last modified: 7/20/2024
    </Typography>

    <Typography variant="body1" paragraph>
      1. Introduction
    </Typography>
    <Typography variant="body1" paragraph>
      Bottle Blue LLC (“Bottle Blue” or “we” or “us”) respects your privacy and is committed to protecting it through our compliance with this policy.
    </Typography>
    <Typography variant="body1" paragraph>
      This Privacy Notice (our “Privacy Notice”) describes the types of information we may collect from you or that you may provide when you visit the website https://www.pocketquant.io (“Website”) and our practices for collecting, using, maintaining, protecting, and disclosing that information.
    </Typography>
    <Typography variant="body1" paragraph>
      This policy applies to information we collect:
    </Typography>
    <ul>
      <li>on our Website;</li>
      <li>in email, text, and other electronic messages between you and our Website; and</li>
      <li>through our mobile application that you download from our Website, the Apple App Store, or Google Play store (“Mobile App”), which provides dedicated non-browser-based interaction between you and our Website.</li>
    </ul>
    <Typography variant="body1" paragraph>
      It does not apply to information collected by:
    </Typography>
    <ul>
      <li>us offline or through any other means, including on any other website operated by Bottle Blue or any third-party;</li>
      <li>us or any of our affiliates or subsidiaries related to your or any other individual’s employment or potential employment with us; or</li>
      <li>any third party, including through any application or content (including advertising) that may link to or be accessible from or on the Website.</li>
    </ul>
    <Typography variant="body1" paragraph>
      Please read this Privacy Notice carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website and/or our Mobile App, you agree to this Privacy Notice. This Privacy Notice may change from time to time (see Changes to Our Privacy Notice). Your continued use of this Website and/or our Mobile App after we make changes is deemed to be acceptance of those changes, so please check this Privacy Notice periodically for updates.
    </Typography>

    <Typography variant="body1" paragraph>
      2. Children Under the Age of 18
    </Typography>
    <Typography variant="body1" paragraph>
      Our Website and Mobile App are not intended for children under 18 years of age. No one under age 18 may provide any information to the Website or Mobile App. We do not knowingly collect Personal Data from children under 18. If you are under 18, do not use our Website or Mobile App, register on the Website or Mobile App, make any purchases through the Website or Mobile App, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received Personal Data from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have information directly from a child under 18, please contact us at legal@pocketquant.io.
    </Typography>

    <Typography variant="body1" paragraph>
      3. Information We Collect About You and How We Collect It
    </Typography>
    <Typography variant="body1" paragraph>
      Throughout this Privacy Notice, the term “Personal Data” means any information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular individual or device. However, Personal Data does not include any deidentified or aggregated information.
    </Typography>

    <Typography variant="body1" paragraph>
      Generally, we collect Personal Data from various sources, including:
    </Typography>
    <ul>
      <li>directly from you when you provide it to us; and</li>
      <li>automatically as you navigate through the Website or Mobile App.</li>
    </ul>

    <Typography variant="body1" paragraph>
      Information You Provide to Us
    </Typography>
    <Typography variant="body1" paragraph>
      We collect the following types of Personal Data directly from you when you access or use our Website or Mobile App or make a purchase of our products or services: real name, username/alias, unique personal identifier, email address, credit or debit card number, and billing address country and zip code. In addition, we also collect other types of information that you may provide when you fill out a form or through your correspondence with us.
    </Typography>
    <Typography variant="body1" paragraph>
      The Personal Data we collect on or through our Website or Mobile App includes:
    </Typography>
    <ul>
      <li>information that you provide by filling in forms on our Website or Mobile App. This includes information provided at the time of registering to use our Website or Mobile App or when subscribing to our services. We may also ask you for information when you report a problem with our Website or Mobile App;</li>
      <li>records and copies of your correspondence (including email addresses), if you contact us;</li>
      <li>your responses to surveys that we might ask you to complete for research purposes; and</li>
      <li>details of transactions you carry out through our Website or Mobile App and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Website or Mobile App.</li>
    </ul>

    <Typography variant="body1" paragraph>
      When you purchase products or services, our payment processor, Stripe, may collect additional Personal Data as described in the Stripe Privacy Policy.
    </Typography>

    <Typography variant="body1" paragraph>
      Information We Collect Through Automatic Data Collection Technologies
    </Typography>
    <Typography variant="body1" paragraph>
      As you navigate through and interact with our Website or Mobile App, we may use automatic data collection technologies to collect certain Personal Data about your interaction with our Website or Mobile App, including information about your equipment, browsing actions, and patterns:
    </Typography>
    <ul>
      <li>details of your visits to our Website or Mobile App. This includes: browsing history, search history, traffic data, location data, logs, referring/exit pages, date and time of your visit to our Website or Mobile App, error information, clickstream data, and other communication data and the resources that you access, use, or otherwise interact with on the Website or Mobile App;</li>
      <li>information about your computer and internet connection, i.e., your IP address, operating system, and browser type.</li>
    </ul>

    <Typography variant="body1" paragraph>
      The information we collect automatically may include Personal Data, or we may maintain it or associate it with Personal Data we collect in other ways or receive from third parties. It helps us to improve our Website or Mobile App and to deliver a better and more personalized service by enabling us to:
    </Typography>
    <ul>
      <li>estimate our audience size and usage patterns;</li>
      <li>store information about your preferences;</li>
      <li>customize our Website or Mobile App according to your individual interests; and</li>
      <li>recognize you when you return to our Website or Mobile App.</li>
    </ul>

    <Typography variant="body1" paragraph>
      Details about the automated data collection technologies and the specific cookies that we use can be found by accessing our cookie consent manager through the “cookies” link of our Website or in our Mobile App.
    </Typography>

    <Typography variant="body1" paragraph>
      The technologies we use for this automatic data collection may include:
    </Typography>
    <ul>
      <li>Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. Our Website or Mobile App may use both session cookies (which expire once you close your browser) and persistent cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Website or Mobile App. Except as otherwise described in a jurisdiction specific addendum that applies to you, our Website will place cookies on your device when you access our Website in accordance with your preferences. You may change your preferences at any time by clicking the “cookies” link on our Website or in the Mobile App. You can also set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. However, if you do not consent to our use of cookies by refusing cookies in your browser you may be unable to access certain parts of our Website or Mobile App. You can find more information about cookies at http://www.allaboutcookies.org and http://youronlinechoices.eu.</li>
      <li>Session Cookies. Our use of cookies also includes “session cookies.” Each time you access the Website, a session cookie containing an encrypted, unique identifier is placed on your browser. These session cookies allow us to uniquely identify

 you when you use the Website and track which pages of the Website you access. Session cookies are required to use certain areas of the Website.</li>
      <li>Web Beacons. Pages of our Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Bottle Blue, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</li>
    </ul>

    <Typography variant="body1" paragraph>
      Third-Party Use of Cookies and Other Tracking Technologies
    </Typography>
    <Typography variant="body1" paragraph>
      Some content or applications on the Website are served by third-parties, including content providers, analytics providers, social media companies, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our Website. The information they collect may be associated with your Personal Data or they may collect information, including Personal Data, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.
    </Typography>

    <Typography variant="body1" paragraph>
      4. How We Use Your Information
    </Typography>
    <Typography variant="body1" paragraph>
      We use information that we collect about you or that you provide to us, including any Personal Data:
    </Typography>
    <ul>
      <li>to provide and personalize our Website or Mobile App and their content to you;</li>
      <li>to provide you with information, products, or services that you request from us;</li>
      <li>to support, develop, troubleshoot, and debug our Website, products, and services;</li>
      <li>to create, maintain, customize, and secure your account with us;</li>
      <li>to process your requests, purchases, transactions, and payments and prevent transactional fraud;</li>
      <li>to provide you with notices about your account and/or subscriptions, including expiration and renewal notices;</li>
      <li>to provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses;</li>
      <li>to help maintain the safety, security, and integrity of our Website, products and services, databases and other technology assets, and business;</li>
      <li>for internal testing, research, analysis, and product development, including to develop and improve our Website, and to develop, improve, or demonstrate our products and services;</li>
      <li>debugging to identify and repair errors that impair existing intended functionality;</li>
      <li>to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</li>
      <li>to notify you about changes to our Website or Mobile App or any products or services we offer or provide through them;</li>
      <li>in any other way we may describe when you provide the information;</li>
      <li>to fulfill any other purpose for which you provide it; and</li>
      <li>for any other purpose with your consent.</li>
    </ul>

    <Typography variant="body1" paragraph>
      We may also use your information to contact you about our own products and services that may be of interest to you. If you wish to stop being contacted, you may do so by clicking the ‘unsubscribe’ button at the bottom of our emails, or by sending us an email stating your request at legal@pocketquant.io.
    </Typography>

    <Typography variant="body1" paragraph>
      5. Disclosure of Your Information
    </Typography>
    <Typography variant="body1" paragraph>
      We do not share, sell, or otherwise disclose your Personal Data for purposes other than those outlined in this Privacy Notice. However, we may disclose aggregated information about our users, and information that does not identify any individual, without restriction.
    </Typography>
    <Typography variant="body1" paragraph>
      We may disclose Personal Data that we collect or you provide as described in this Privacy Notice:
    </Typography>
    <ul>
      <li>to our subsidiaries and affiliates;</li>
      <li>to contractors, service providers, and other third parties we use to support our business. These entities provide IT and infrastructure support services and payment processing services. Our payment processors’ privacy policies may be found at https://stripe.com/privacy;</li>
      <li>to a potential or actual buyer or other successor in the event of a planned or actual merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Bottle Blue’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Bottle Blue about our Website’s and/or Mobile App’s users is among the assets transferred;</li>
      <li>to fulfill the purpose for which you provide it;</li>
      <li>for any other purpose disclosed by us when you provide the information; and</li>
      <li>with your consent.</li>
    </ul>

    <Typography variant="body1" paragraph>
      We may also disclose your Personal Data:
    </Typography>
    <ul>
      <li>to comply with any court order, law, or legal process, including to respond to any government or regulatory request;</li>
      <li>to enforce or apply our terms of use and other agreements, including for billing and collection purposes; and</li>
      <li>if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Bottle Blue, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
    </ul>

    <Typography variant="body1" paragraph>
      6. Choices About How We Use and Disclose Your Information
    </Typography>
    <Typography variant="body1" paragraph>
      We do not control the collection and use of your information collected by third parties described above in Disclosure of Your Information. When possible, these organizations are under contractual obligations to use this data only for providing the services to us and to keep this information strictly confidential. These third parties may, however, aggregate the information they collect with information from their other customers for their own purposes.
    </Typography>
    <Typography variant="body1" paragraph>
      In addition, we strive to provide you with choices regarding the Personal Data you provide to us. We have created mechanisms to provide you with control over your Personal Data:
    </Typography>
    <ul>
      <li>Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of our Website or Mobile App may then be inaccessible or not function properly.</li>
      <li>Promotional Offers from Bottle Blue. We will only use your email address to promote our own products and services. If we have sent you a promotional email, you may click the unsubscribe link in the body of that email to be omitted from future email distributions. This opt out does not apply to information provided to Bottle Blue as a result of a product or service purchase.</li>
    </ul>

    <Typography variant="body1" paragraph>
      We do not control third parties’ collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can learn more about interest-based advertisements and your opt-out rights and options from members of the Network Advertising Initiative (“NAI”) on its website (www.networkadvertising.org) and from members of the Digital Advertising Alliance on its website (www.aboutads.info).
    </Typography>

    <Typography variant="body1" paragraph>
      7. Accessing, Correcting, and Deleting Your Personal Data
    </Typography>
    <Typography variant="body1" paragraph>
      You may send us an email at legal@pocketquant.io to request access to, correct, or delete any Personal Data that you have provided to us. We cannot delete your Personal Data except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
    </Typography>
    <Typography variant="body1" paragraph>
      The jurisdiction in which you are a resident or are located may provide you with additional rights and choices regarding your Personal Data. Please see Jurisdiction-Specific Privacy Rights for more Information.
    </Typography>

    <Typography variant="body1" paragraph>
      8. Jurisdiction-Specific Privacy Rights
    </Typography>
    <Typography variant="body1" paragraph>
      The law in some jurisdictions may provide you with additional rights regarding our use of Personal Data. To learn more about any additional rights that may be applicable to you as a resident of one of these jurisdictions, please see the privacy addendum for your state that is attached to this Privacy Notice.
    </Typography>
    <Typography variant="body1" paragraph>
      Your GDPR Privacy Rights
    </Typography>
    <Typography variant="body1" paragraph>
      If you are a resident of the European Economic Area, Switzerland, or the United Kingdom, you have the additional rights described in our GDPR Privacy Addendum.
    </Typography>

    <Typography variant="body1" paragraph>
      9. Data Security
    </Typography>
    <Typography

 variant="body1" paragraph>
      We have implemented measures designed to secure your Personal Data from accidental loss and from unauthorized access, use, alteration, and disclosure. Any payment transactions will be encrypted using SSL technology.
    </Typography>
    <Typography variant="body1" paragraph>
      The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website or Mobile App, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
    </Typography>
    <Typography variant="body1" paragraph>
      Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your Personal Data, we cannot guarantee the security of your Personal Data transmitted to our Website or Mobile App. Any transmission of Personal Data is at your own risk. We are not responsible for circumvention of any privacy settings or security measures deployed on the Website or Mobile App.
    </Typography>

    <Typography variant="body1" paragraph>
      10. Consent to Processing of Personal Data in the United States
    </Typography>
    <Typography variant="body1" paragraph>
      In order to provide our Website or Mobile App, products, and services to you, we may send and store your Personal Data outside of the country where you reside or are located, including to countries that may not or do not provide an equivalent level of protection for your Personal Data. Your Personal Data may be processed and stored in the United States and United States federal, state, and local governments, courts, or law enforcement or regulatory agencies may be able to obtain disclosure of your information through the laws of the United States. By using our Website or Mobile App, you represent that you have read and understood the above and hereby consent to the storage and processing of Personal Data outside the country where you reside or are located, including in the United States.
    </Typography>
    <Typography variant="body1" paragraph>
      Your Personal Data is transferred by Bottle Blue to another country only if it is required or permitted under applicable data protection law and provided that there are appropriate safeguards in place to protect your Personal Data. To ensure your Personal Data is treated in accordance with this Privacy Notice when we transfer it to a third party, Bottle Blue uses Data Protection Agreements between Bottle Blue and all other recipients of your Personal Data when legally required.
    </Typography>

    <Typography variant="body1" paragraph>
      11. Changes to Our Privacy Notice
    </Typography>
    <Typography variant="body1" paragraph>
      We may change this Privacy Notice at any time. It is our policy to post any changes we make to our Privacy Notice on this page. If we make material changes to how we treat our users’ Personal Data, we will notify you by email to the email address specified in your account and/or through a notice on the Website or Mobile App’ home page. The date this Privacy Notice was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website or Mobile App and this Privacy Notice to check for any changes.
    </Typography>
    <Typography variant="body1" paragraph>
      YOUR CONTINUED USE OF OUR WEBSITE OR MOBILE APP FOLLOWING THE POSTING OF CHANGES CONSTITUTES YOUR ACCEPTANCE TO SUCH CHANGES.
    </Typography>

    <Typography variant="body1" paragraph>
      12. Contact Information
    </Typography>
    <Typography variant="body1" paragraph>
      If you have any questions, concerns, complaints, or suggestions regarding our Privacy Notice or the ways in which we collect and use your Personal Data described in this Privacy Notice, have any requests related to your Personal Data pursuant to applicable laws, or otherwise need to contact us, you may contact us at the contact information below or through the “Contact” page on our Website or Mobile App.
    </Typography>
    <Typography variant="body1" paragraph>
      Bottle Blue LLC
    </Typography>
    <Typography variant="body1" paragraph>
      415 3rd Street SW
    </Typography>
    <Typography variant="body1" paragraph>
      Montgomery, MN 56059
    </Typography>
    <Typography variant="body1" paragraph>
      business@bottleblue.biz
    </Typography>
  </Box>
);

export default PrivacyPolicy;