import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card, CardContent, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { format, addDays, getDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: '400px', // Adjust the max width here
  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
  borderRadius: '10px',
  padding: '1rem',
  margin: '0 auto', // Center the card horizontally
  [theme.breakpoints.down('xs')]: {
    maxWidth: '100%',
  },
}));

const StyledButton = styled(Button)({
  backgroundColor: '#d92d20',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#bf2619',
  },
  marginTop: '1rem',
  padding: '0.75rem',
  fontSize: '1rem',
});

const FAQCard = styled(Card)({
  backgroundColor: '#333',
  color: '#fff',
  margin: '1rem',
  minWidth: '250px',
  textAlign: 'center',
  flexGrow: 1,
});

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // New state to manage loading

  const getNextMonday = () => {
    const today = new Date();
    const daysUntilNextMonday = (8 - getDay(today)) % 7 || 7;
    const nextMonday = addDays(today, daysUntilNextMonday);
  
    // Set next Monday to 8:00 AM in CST timezone
    const timeZone = 'America/Chicago';
    const nextMondayAt8AM = new Date(nextMonday.setHours(8, 0, 0, 0));
  
    // Format the date in CST timezone
    const nextMondayCST = format(nextMondayAt8AM, 'EEEE, MMMM d, yyyy \'at\' h:mm a \'CST\'', { timeZone });
  
    return nextMondayCST;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }
    setOpenDisclaimer(true);
  };

  const handleDisclaimerClose = async (agree) => {
    setOpenDisclaimer(false);
    if (agree) {
      setLoading(true); // Start loading
      try {
        await axios.post('https://8poesonquj.execute-api.us-east-1.amazonaws.com/prod/subscribe', { email });
        setOpenSuccess(true);
        setEmail('');
        setErrorMessage('');
      } catch (error) {
        setErrorMessage('Something went wrong. Please try again.');
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" padding="1rem">
      <img src="logo.svg" alt="Pocket Quant Logo" style={{ width: '100%', maxWidth: '250px', height: 'auto', marginBottom: '1.5rem' }} />
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontSize: '1.25rem',
          maxWidth: '600px',
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        Stay informed with the latest insights on insider trading, congressional trades, the housing market, finance, and economics. 
        <br /> <br />
        Our AI-powered analysis delivers comprehensive updates.
        <br /> <br />
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom align="center">
                Join Our Community
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Enter your email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!errorMessage}
                  helperText={errorMessage}
                />
                <StyledButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={loading} // Disable button when loading
                >
                  {loading ? 'Signing Up...' : 'Sign Up'}
                </StyledButton>
              </form>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      <Dialog
        open={openDisclaimer}
        onClose={() => handleDisclaimerClose(false)}
      >
        <DialogTitle>Disclaimer</DialogTitle>
        <DialogContent>
        <DialogContentText>
          This is not financial advice. The information provided is for informational purposes only and should not be considered financial, legal, or professional advice. By subscribing, you agree to our terms and conditions and acknowledge that you should conduct your own research and consult with a qualified financial advisor before making any financial decisions.
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDisclaimerClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDisclaimerClose(true)} color="primary" autoFocus>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSuccess}
        onClose={handleSuccessClose}
      >
        <DialogTitle>Welcome</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have successfully subscribed! The next newsletter will be sent out on {getNextMonday()}. Please add no-reply@pocketquant.io to your address book or mark it as a permitted sender.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={4} width="100%">
        <Typography variant="h4" gutterBottom align="center">
          Frequently Asked Questions
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={3}>
            <FAQCard>
              <CardContent>
              <Typography variant="h6" gutterBottom>
                What is Pocket Quant?
              </Typography>
              <Typography variant="body2">
                Pocket Quant is a weekly newsletter offering cutting-edge insights into insider trading, congressional trades, the housing market, finance, and economics, all powered by advanced AI analysis.
              </Typography>
              </CardContent>
            </FAQCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FAQCard>
              <CardContent>
              <Typography variant="h6" gutterBottom>
                How do I subscribe to the newsletter?
              </Typography>
              <Typography variant="body2">
                You can subscribe by entering your email address in the form above and clicking the 'Sign Up' button. Rest assured, your email will be stored securely.
              </Typography>
              </CardContent>
            </FAQCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FAQCard>
              <CardContent>
              <Typography variant="h6" gutterBottom>
                Is the newsletter free?
              </Typography>
              <Typography variant="body2">
                Yes, the Pocket Quant newsletter is completely free and does not require any account creation or credit card. It's a valuable tool designed to empower the working class with critical insights.
              </Typography>
              </CardContent>
            </FAQCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FAQCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  How can I unsubscribe?
                </Typography>
                <Typography variant="body2">
                  You can unsubscribe at any time by clicking the unsubscribe link in any of the emails you receive from us or by contacting us directly.
                </Typography>
              </CardContent>
            </FAQCard>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SignUp;

// import React, { useState } from 'react';
// import axios from 'axios';
// import { TextField, Button, Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card, CardContent, Grid } from '@mui/material';
// import { styled } from '@mui/system';
// import { format, addDays, getDay } from 'date-fns';
// import { toZonedTime } from 'date-fns-tz';

// const StyledCard = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: '400px', // Adjust the max width here
//   boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
//   borderRadius: '10px',
//   padding: '1rem',
//   margin: '0 auto', // Center the card horizontally
//   [theme.breakpoints.down('xs')]: {
//     maxWidth: '100%',
//   },
// }));

// const StyledButton = styled(Button)({
//   backgroundColor: '#d92d20',
//   color: '#fff',
//   '&:hover': {
//     backgroundColor: '#bf2619',
//   },
//   marginTop: '1rem',
//   padding: '0.75rem',
//   fontSize: '1rem',
// });

// const FAQCard = styled(Card)({
//   backgroundColor: '#333',
//   color: '#fff',
//   margin: '1rem',
//   minWidth: '250px',
//   textAlign: 'center',
//   flexGrow: 1,
// });

// const SignUp = () => {
//   const [email, setEmail] = useState('');
//   const [openDisclaimer, setOpenDisclaimer] = useState(false);
//   const [openSuccess, setOpenSuccess] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [loading, setLoading] = useState(false); // New state to manage loading

//   const getNextMonday = () => {
//     const today = new Date();
//     const daysUntilNextMonday = (8 - getDay(today)) % 7 || 7;
//     const nextMonday = addDays(today, daysUntilNextMonday);
  
//     // Set next Monday to 8:00 AM in CST timezone
//     const timeZone = 'America/Chicago';
//     const nextMondayAt8AM = new Date(nextMonday.setHours(8, 0, 0, 0));
  
//     // Format the date in CST timezone
//     const nextMondayCST = format(nextMondayAt8AM, 'EEEE, MMMM d, yyyy \'at\' h:mm a \'CST\'', { timeZone });
  
//     return nextMondayCST;
//   };
  
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validateEmail(email)) {
//       setErrorMessage('Please enter a valid email address.');
//       return;
//     }
//     setOpenDisclaimer(true);
//   };

//   const handleDisclaimerClose = async (agree) => {
//     setOpenDisclaimer(false);
//     if (agree) {
//       setLoading(true); // Start loading
//       try {
//         await axios.post('https://8poesonquj.execute-api.us-east-1.amazonaws.com/prod/subscribe', { email });
//         setOpenSuccess(true);
//         setEmail('');
//         setErrorMessage('');
//       } catch (error) {
//         setErrorMessage('Something went wrong. Please try again.');
//       } finally {
//         setLoading(false); // Stop loading
//       }
//     }
//   };

//   const validateEmail = (email) => {
//     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return re.test(String(email).toLowerCase());
//   };

//   const handleSuccessClose = () => {
//     setOpenSuccess(false);
//   };

//   return (
//     <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" padding="1rem">
//       <img src="logo.svg" alt="Pocket Quant Logo" style={{ marginBottom: '-425px',  marginTop: '-445px' }} />
//       {/* <Typography variant="h2" gutterBottom sx={{ fontWeight: 'bold', fontSize: '3rem' }}>
//         Pocket Quant
//       </Typography> */}
//       <Typography
//         variant="h6"
//         gutterBottom
//         sx={{
//           fontSize: '1.25rem',
//           maxWidth: '600px',
//           margin: '0 auto',
//           textAlign: 'center'
//         }}
//       >
//         Stay informed with the latest insights on insider trading, congressional trades, the housing market, finance, and economics. 
//         <br /> <br />
//         Our AI-powered analysis delivers comprehensive updates.
//         <br /> <br />
//       </Typography>
//       <Grid container spacing={2} justifyContent="center">
//         <Grid item xs={12} sm={8} md={6}>
//           <StyledCard>
//             <CardContent>
//               <Typography variant="h6" gutterBottom align="center">
//                 Join Our Community
//               </Typography>
//               <form onSubmit={handleSubmit}>
//                 <TextField
//                   label="Enter your email"
//                   variant="outlined"
//                   fullWidth
//                   margin="normal"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   error={!!errorMessage}
//                   helperText={errorMessage}
//                 />
//                 <StyledButton
//                   variant="contained"
//                   color="primary"
//                   type="submit"
//                   fullWidth
//                   disabled={loading} // Disable button when loading
//                 >
//                   {loading ? 'Signing Up...' : 'Sign Up'}
//                 </StyledButton>
//               </form>
//             </CardContent>
//           </StyledCard>
//         </Grid>
//       </Grid>
//       <Dialog
//         open={openDisclaimer}
//         onClose={() => handleDisclaimerClose(false)}
//       >
//         <DialogTitle>Disclaimer</DialogTitle>
//         <DialogContent>
//         <DialogContentText>
//           This is not financial advice. The information provided is for informational purposes only and should not be considered financial, legal, or professional advice. By subscribing, you agree to our terms and conditions and acknowledge that you should conduct your own research and consult with a qualified financial advisor before making any financial decisions.
//         </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDisclaimerClose(false)} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={() => handleDisclaimerClose(true)} color="primary" autoFocus>
//             Accept
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <Dialog
//         open={openSuccess}
//         onClose={handleSuccessClose}
//       >
//         <DialogTitle>Welcome</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             You have successfully subscribed! The next newsletter will be sent out on {getNextMonday()}. Please add no-reply@pocketquant.io to your address book or mark it as a permitted sender.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleSuccessClose} color="primary" autoFocus>
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <Box mt={4} width="100%">
//         <Typography variant="h4" gutterBottom align="center">
//           Frequently Asked Questions
//         </Typography>
//         <Grid container spacing={2} justifyContent="center">
//           <Grid item xs={12} sm={6} md={3}>
//             <FAQCard>
//               <CardContent>
//               <Typography variant="h6" gutterBottom>
//                 What is Pocket Quant?
//               </Typography>
//               <Typography variant="body2">
//                 Pocket Quant is a weekly newsletter offering cutting-edge insights into insider trading, congressional trades, the housing market, finance, and economics, all powered by advanced AI analysis.
//               </Typography>
//               </CardContent>
//             </FAQCard>
//           </Grid>
//           <Grid item xs={12} sm={6} md={3}>
//             <FAQCard>
//               <CardContent>
//               <Typography variant="h6" gutterBottom>
//                 How do I subscribe to the newsletter?
//               </Typography>
//               <Typography variant="body2">
//                 You can subscribe by entering your email address in the form above and clicking the 'Sign Up' button. Rest assured, your email will be stored securely.
//               </Typography>
//               </CardContent>
//             </FAQCard>
//           </Grid>
//           <Grid item xs={12} sm={6} md={3}>
//             <FAQCard>
//               <CardContent>
//               <Typography variant="h6" gutterBottom>
//                 Is the newsletter free?
//               </Typography>
//               <Typography variant="body2">
//                 Yes, the Pocket Quant newsletter is completely free and does not require any account creation or credit card. It's a valuable tool designed to empower the working class with critical insights.
//               </Typography>
//               </CardContent>
//             </FAQCard>
//           </Grid>
//           <Grid item xs={12} sm={6} md={3}>
//             <FAQCard>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>
//                   How can I unsubscribe?
//                 </Typography>
//                 <Typography variant="body2">
//                   You can unsubscribe at any time by clicking the unsubscribe link in any of the emails you receive from us or by contacting us directly.
//                 </Typography>
//               </CardContent>
//             </FAQCard>
//           </Grid>
//         </Grid>
//       </Box>
//     </Box>
//   );
// };

// export default SignUp;

// import React, { useState } from 'react';
// import axios from 'axios';
// import { TextField, Button, Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card, CardContent, Grid } from '@mui/material';
// import { styled } from '@mui/system';

// const StyledCard = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: '400px', // Adjust the max width here
//   boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
//   borderRadius: '10px',
//   padding: '1rem',
//   margin: '0 auto', // Center the card horizontally
//   [theme.breakpoints.down('xs')]: {
//     maxWidth: '100%',
//   },
// }));

// const StyledButton = styled(Button)({
//   backgroundColor: '#d92d20',
//   color: '#fff',
//   '&:hover': {
//     backgroundColor: '#bf2619',
//   },
//   marginTop: '1rem',
//   padding: '0.75rem',
//   fontSize: '1rem',
// });

// const FAQCard = styled(Card)({
//   backgroundColor: '#333',
//   color: '#fff',
//   margin: '1rem',
//   minWidth: '250px',
//   textAlign: 'center',
//   flexGrow: 1,
// });

// const SignUp = () => {
//   const [email, setEmail] = useState('');
//   const [openDisclaimer, setOpenDisclaimer] = useState(false);
//   const [openSuccess, setOpenSuccess] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [loading, setLoading] = useState(false); // New state to manage loading

//   const getNextMonday = () => {
//     const today = new Date();
//     const nextMonday = new Date(today);
//     nextMonday.setDate(today.getDate() + ((8 - today.getDay()) % 7 || 7));
//     nextMonday.setHours(8, 0, 0, 0); // Set time to 8 AM
//     return nextMonday.toLocaleString('en-US', {
//       weekday: 'long',
//       month: 'long',
//       day: 'numeric',
//       year: 'numeric',
//       hour: 'numeric',
//       minute: 'numeric',
//       hour12: true
//     }) + ' CST';
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validateEmail(email)) {
//       setErrorMessage('Please enter a valid email address.');
//       return;
//     }
//     setOpenDisclaimer(true);
//   };

//   const handleDisclaimerClose = async (agree) => {
//     setOpenDisclaimer(false);
//     if (agree) {
//       setLoading(true); // Start loading
//       try {
//         await axios.post('https://8poesonquj.execute-api.us-east-1.amazonaws.com/prod/subscribe', { email });
//         setOpenSuccess(true);
//         setEmail('');
//         setErrorMessage('');
//       } catch (error) {
//         setErrorMessage('Something went wrong. Please try again.');
//       } finally {
//         setLoading(false); // Stop loading
//       }
//     }
//   };

//   const validateEmail = (email) => {
//     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return re.test(String(email).toLowerCase());
//   };

//   const handleSuccessClose = () => {
//     setOpenSuccess(false);
//   };

//   return (
//     <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" padding="1rem">
//       <Typography variant="h2" gutterBottom sx={{ fontWeight: 'bold', fontSize: '3rem' }}>
//         Pocket Quant
//       </Typography>
//       <Typography
//         variant="h6"
//         gutterBottom
//         sx={{
//           fontSize: '1.25rem',
//           maxWidth: '600px',
//           margin: '0 auto',
//           textAlign: 'center'
//         }}
//       >
//         Stay informed with the latest insights on insider trading, congressional trades, the housing market, finance, and economics. 
//         <br /> <br />
//         Our AI-powered analysis delivers comprehensive updates.
//         <br /> <br />
//       </Typography>
//       <Grid container spacing={2} justifyContent="center">
//         <Grid item xs={12} sm={8} md={6}>
//           <StyledCard>
//             <CardContent>
//               <Typography variant="h6" gutterBottom align="center">
//                 Join Our Community
//               </Typography>
//               <form onSubmit={handleSubmit}>
//                 <TextField
//                   label="Enter your email"
//                   variant="outlined"
//                   fullWidth
//                   margin="normal"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   error={!!errorMessage}
//                   helperText={errorMessage}
//                 />
//                 <StyledButton
//                   variant="contained"
//                   color="primary"
//                   type="submit"
//                   fullWidth
//                   disabled={loading} // Disable button when loading
//                 >
//                   {loading ? 'Signing Up...' : 'Sign Up'}
//                 </StyledButton>
//               </form>
//             </CardContent>
//           </StyledCard>
//         </Grid>
//       </Grid>
//       <Dialog
//         open={openDisclaimer}
//         onClose={() => handleDisclaimerClose(false)}
//       >
//         <DialogTitle>Disclaimer</DialogTitle>
//         <DialogContent>
//         <DialogContentText>
//           This is not financial advice. The information provided is for informational purposes only and should not be considered financial, legal, or professional advice. By subscribing, you agree to our terms and conditions and acknowledge that you should conduct your own research and consult with a qualified financial advisor before making any financial decisions.
//         </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDisclaimerClose(false)} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={() => handleDisclaimerClose(true)} color="primary" autoFocus>
//             Accept
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <Dialog
//         open={openSuccess}
//         onClose={handleSuccessClose}
//       >
//         <DialogTitle>Welcome</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             You have successfully subscribed! The next newsletter will be sent out on {getNextMonday()}. Please add no-reply@pocketquant.io to your address book or mark it as a permitted sender.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleSuccessClose} color="primary" autoFocus>
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <Box mt={4} width="100%">
//         <Typography variant="h4" gutterBottom align="center">
//           Frequently Asked Questions
//         </Typography>
//         <Grid container spacing={2} justifyContent="center">
//           <Grid item xs={12} sm={6} md={3}>
//             <FAQCard>
//               <CardContent>
//               <Typography variant="h6" gutterBottom>
//                 What is Pocket Quant?
//               </Typography>
//               <Typography variant="body2">
//                 Pocket Quant is a weekly newsletter offering cutting-edge insights into insider trading, congressional trades, the housing market, finance, and economics, all powered by advanced AI analysis.
//               </Typography>
//               </CardContent>
//             </FAQCard>
//           </Grid>
//           <Grid item xs={12} sm={6} md={3}>
//             <FAQCard>
//               <CardContent>
//               <Typography variant="h6" gutterBottom>
//                 How do I subscribe to the newsletter?
//               </Typography>
//               <Typography variant="body2">
//                 You can subscribe by entering your email address in the signup form above and clicking "Sign Up". To ensure you continue receiving our updates, please add no-reply@pocketquant.io to your address book or mark it as a permitted sender.
//               </Typography>
//               </CardContent>
//             </FAQCard>
//           </Grid>
//           <Grid item xs={12} sm={6} md={3}>
//             <FAQCard>
//               <CardContent>
//               <Typography variant="h6" gutterBottom>
//                 Is the newsletter free?
//               </Typography>
//               <Typography variant="body2">
//                 Yes, the Pocket Quant newsletter is completely free and does not require any account creation or credit card. It's a valuable tool designed to empower the working class with critical financial insights.
//               </Typography>
//               </CardContent>
//             </FAQCard>
//           </Grid>
//           <Grid item xs={12} sm={6} md={3}>
//             <FAQCard>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>
//                   How can I unsubscribe?
//                 </Typography>
//                 <Typography variant="body2">
//                   You can unsubscribe at any time by clicking the unsubscribe link in any of the emails you receive from us or by contacting us directly.
//                 </Typography>
//               </CardContent>
//             </FAQCard>
//           </Grid>
//         </Grid>
//       </Box>
//     </Box>
//   );
// };

// export default SignUp;