import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#1c1c1c',  // Matching background color
  color: '#fff',  // White text color
  borderRadius: '10px',
  boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const Disclaimer = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      <StyledContainer maxWidth="md">
        <Typography variant="h4" gutterBottom align="center">
          Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          Services provided by Bottle Blue LLC through the Pocket Quant application are subject to the following disclaimers, which may be changed by us without any prior notice:
        </Typography>
        <Typography variant="body1" paragraph>
          1. Our past backtests, analysis, and Strategy results are not a guarantee of future results. Using any graph, chart, formula, or other device to assist in deciding which securities to trade or when to trade them presents many difficulties and their effectiveness has significant limitations, including that prior patterns may not repeat themselves continuously or on any particular occasion. In addition, market participants using such devices can impact the market in a way that changes the effectiveness of such device.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Due to various factors, including limitations of the data collection process and our underlying sources, the content on our site may be lagged, outdated, or otherwise inaccurate.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Bottle Blue LLC is neither a law firm nor a certified public accounting firm and no portion of our website, our newsletter content or any other correspondence from us should be construed as legal or accounting advice.
        </Typography>
        <Typography variant="body1" paragraph>
          4. Nothing contained on our Site constitutes a solicitation, recommendation, endorsement, or offer by Bottle Blue LLC or any third party service provider to buy or sell any securities or other financial instruments in this or in any other jurisdiction in which such solicitation or offer would be unlawful under the securities laws of such jurisdiction.
        </Typography>
        <Typography variant="body1" paragraph>
          5. Pocket Quant’s website utilizes different technologies to collect, store, and aggregate data about website usage. We may use electronic tags called “cookies” to help us understand and analyze use of our site. This work is either performed directly by us or by a third party we’ve hired to assist us. We collect information about which pages have been accessed and for how long, the country the user accesses the site from, and certain technical information regarding the user’s computer and operating systems, such as user Internet protocol address, domain name and browser, etc. Certain sections of Pocket Quant’s site require cookies to be enabled to enhance site performance. For example, cookies provide a secure way for us to verify user identity during a session and any return visits, they enable us to personalize a user’s experience on our sites, and they help enhance site navigation. Cookies also help us to understand how people use our sites so we can improve site functionality.
        </Typography>
      </StyledContainer>
    </Box>
  );
};

export default Disclaimer;
